import Swiper from "swiper"
import {Navigation, Pagination} from "swiper/modules"

const siteSliders = document.querySelectorAll('.site__slider')
if(siteSliders.length > 0){
    siteSliders.forEach((slider, index) => {
        index++
        const id = `slider-${index}`
        slider.id = id

        new Swiper(`#${id}`, {
            modules: [Navigation],
            spaceBetween: 11,
            navigation: {
                nextEl: `#${id} .site__slider-arrow_next`,
                prevEl: `#${id} .site__slider-arrow_prev`,
                disabledClass: 'site__slider-arrow_disabled'
            }
        })
    })
}

const gallerySLiders = document.querySelectorAll('.gallery__slider')
if(gallerySLiders.length > 0){
    gallerySLiders.forEach((slider, index) => {
        index++
        const id = `gallery-${index}`
        slider.closest('.gallery').id = id

        new Swiper(`#${id} .swiper`, {
            modules: [Navigation],
            slidesPerView: 2,
            spaceBetween: 11,
            navigation: {
                nextEl: `#${id} .gallery__slider-arrow_next`,
                prevEl: `#${id} .gallery__slider-arrow_prev`,
                disabledClass: 'gallery__slider-arrow_disabled'
            },
            breakpoints: {
                768: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 4
                }
            }
        })
    })
}

const layoutsSliders = document.querySelectorAll('.layouts__slider')
if(layoutsSliders.length > 0){
    layoutsSliders.forEach((slider, index) => {
        index++
        const id = `layouts-${index}`
        slider.id = id

        const layoutSlider = new Swiper(`#${id}`, {
            modules: [Navigation, Pagination],
            spaceBetween: 9,
            autoHeight: true,
            allowTouchMove: false,
            navigation: {
                nextEl: `#${id} .layouts__slider-arrow_next`,
                prevEl: `#${id} .layouts__slider-arrow_prev`,
                disabledClass: 'layouts__slider-arrow_disabled'
            },
            pagination: {
                el: `#${id} .layouts__slider-pagination`,
                clickable: true,
                bulletActiveClass: 'layouts__slider-bullet_active',
                bulletClass: 'layouts__slider-bullet',
                renderBullet: (index, className) => {
                    return `<span class="${className}"><svg><use xlink:href="images/sprite..svg#sliderBullet" /></svg></span>`
                }
            },
        })

        const layoutsTypes = slider.parentNode.querySelectorAll('.layouts__typesList .item')
        if(layoutsTypes.length > 0){
            layoutsTypes.forEach(item => {
                item.addEventListener('click', () => {
                    layoutSlider.slideTo(item.dataset?.index ?? 0)
                    layoutsTypes.forEach(el => el.classList.remove('active'))
                    item.classList.add('active')
                })
            })
        }

        layoutSlider.on('slideChangeTransitionStart', (swiper) => {
            const activeIndex = swiper.activeIndex
            layoutsTypes.forEach(el => el.classList.remove('active'))
            slider.parentNode.querySelector(`.layouts__typesList .item[data-index="${activeIndex}"]`).classList.add('active')
            slider.parentNode.parentNode.scrollIntoView(true)
        })
    })
}
require('fslightbox')

const toggleBodyScroll = (enabled = true) => {
    const body = document.body
    if(enabled){
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
        body.style.overflow = 'hidden'
        body.style.paddingRight = `${scrollbarWidth}px`
    } else {
        body.style.overflow = null
        body.style.paddingRight = null
    }
}

const modalMask = document.querySelector('.site__modal-mask')

const closeModal = () => {
    document.querySelectorAll('.site__modal, .site__modal-mask').forEach(el => {
        setTimeout(() => toggleBodyScroll(false), 300)
        el.classList.remove('opened')
    })
}

const setModal = name => {
    let modal = document.getElementById(name)
    if(modal){
        toggleBodyScroll()
        modal.querySelector('.site__modal-close').addEventListener('click', () => closeModal())
        modalMask.classList.add('opened')
        modal.classList.add('opened')
    }
}
if(modalMask) modalMask.addEventListener('click', () => closeModal())

let buttons = document.querySelectorAll('[data-modal]')
if(buttons.length > 0){
    buttons.forEach(el => {
        let target = el.dataset?.modal
        if(target){
            el.addEventListener('click', e => {
                e.preventDefault()
                setModal(target)
            })
        }
    })
}